#calendly > iframe {
    height: 850px;
    filter: hue-rotate(339deg);
    border-radius: 10px;
    -webkit-overflow-scroll: touch
}

#calendly.shrinked > iframe {
    height: 550px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@media(max-width: 1049px) {
    #calendly > iframe {
        /*height: 750px;*/

    }

    .calendly-overlay {
        z-index: 9400 !important;
    }

    .calendly-badge-widget {
        display: none;
    }

    .calendly-popup {
        top: 0 !important;
        bottom: 58px !important;
        background: white;
    }

    .shrinked .calendly-popup, .shrinked .calendly-overlay {
        bottom: 115px !important;
    }

    .calendly-popup-close {
        pointer-events: none;
        display: none;
    }

    .calendly-mobile {
        -webkit-overflow-scrolling: touch !important;
        overflow: auto !important;
    }
}
