.zEWidget-launcher {
    margin: 0 !important;
    margin-bottom: -6px !important;
    margin-right: 40px !important;
    z-index: 3000 !important;
    bottom: 10px !important;
}

@media (max-width: 559px) {
    .zEWidget-launcher {
        margin-right: 5px !important;
        z-index: 3001 !important;
        /*transform: translateZ(3001px) !important;*/
        -webkit-transform: translate3d(0, 0, 0) !important;
        /*margin-bottom: 63px !important;*/
        margin-bottom: 53px !important;
    }

    .zEWidget-webWidget--active {
        /*transform: translateZ(3001px) !important;*/
        -webkit-transform: translate3d(0, 0, 0) !important;
    }
}
