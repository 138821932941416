/* Height for the transition container */
.DayPicker_transitionContainer {
    height: 247px !important;
}

.DayPicker {
    box-shadow: none;
    margin-left: -9px;
    background: transparent;
}

.DayPickerNavigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.DayPicker_weekHeader {
    top: 30px;
    padding: 0 !important;
    text-transform: uppercase;
    letter-spacing: 0.75px;
}

.DayPicker_weekHeader small {
    font-size: 9px !important;
    color: var(--black);
    font-weight: 500;
}

.DayPicker_weekHeader_ul {
    padding-left: 6px;
}

/*.payment .DayPicker_weekHeader:nth-child(2) {*/
/*    left: -8px !important;*/
/*}*/

/*.payment .DayPicker_weekHeader:nth-child(2) {*/
/*    left: 278px !important;*/
/*}*/

/*.payment .DayPicker_weekHeader:nth-child(3) {*/
/*    left: 566px !important;*/
/*}*/
.CalendarMonthGrid {
    background: transparent;
}

.CalendarMonthGrid__horizontal {
    left: 0;
}

.CalendarMonth {
}

.CalendarMonth_caption {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
    padding-top: 5px;
}

.payment .CalendarDay {
    height: 30px !important;
    width: 31px !important;
    border: 1px solid transparent;
    border-radius: 50%;
    color: var(--black);
    font-size: 11px;
    vertical-align: middle;
}


/* Z-index container for each day in calendar */
.payment .CalendarDay__selected_start, .payment .CalendarDay__selected_end {
    background: transparent;
    border: 0;
    color: white !important;
    position: relative;
    z-index: 1;
}

/* Blue background behind the blue circle */
.CalendarDay__selected_end > div::before, .CalendarDay__selected_start > div::before {
    position: absolute;
    left: -1px;
    top: 5px;
    height: 20px;
    width: 15px;
    background: var(--light-blue);
    content: ' ';
    z-index: -2;
}

/* Blue circle for the selected start and end dates */
.CalendarDay__selected_end > div::after, .CalendarDay__selected_start > div::after {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--dark-blue);
    content: ' ';
    z-index: -1;
}

/* Positioning override for the start date */
.CalendarDay__selected_start > div::before, .CalendarDay__selected_start > div::after {
    right: 0 !important;
    left: auto;
}


.payment .CalendarDay {
    background: transparent;
    border: 0;
    color: var(--black);
    position: relative;
    z-index: 1;
}

.payment .CalendarDay__selected_span,
.payment .CalendarDay__hovered_span {
    background: var(--light-blue);
    border-top: 0 !important;
    border-radius: 0;
}

.CalendarDay__selected_span > div::before,
.CalendarDay__hovered_span > div::before {
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 31px;
    height: 5px;
    content: ' ';
}

.CalendarDay__selected_span > div::after,
.CalendarDay__hovered_span > div::after {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 31px;
    height: 5px;
    content: ' ';
}

/* Arrow disabled style */
.DayPickerNavigation_button__disabled {
    border: none;
}

.DayPickerNavigation_button__disabled:hover {
    border: none;
}

/* Hide help button */
.DayPickerKeyboardShortcuts_show__bottomRight,
.DayPickerKeyboardShortcuts_show__topRight {
    display: none;
}

.CalendarDay__outside {
    pointer-events: none;
}

.CalendarDay__outside > div {
    color: var(--dark-gray);
}

.CalendarDay__outside {
    background: transparent !important;
}

.CalendarDay__outside > div::before,
.CalendarDay__outside > div::after {
    display: none;
}

.CalendarDay__blocked_out_of_range > div {
    color: var(--dark-gray);
}

/* Responsiveness */

@media (min-width: 769px) and (max-width: 1049px) {
    .DayPicker {
        margin: auto;
    }

    .DayPicker_weekHeader {
        transform: translateX(25px);
    }

}

/*@media (max-width: 768px) {*/
@media (max-width: 768px) {

    .mobileDatepicker .DayPicker_weekHeader {
        top: 0px;
        padding: 0 !important;
        text-transform: capitalize;
        letter-spacing: 0.75px;
        /*transform: translateZ(10000px);*/
        -webkit-transform: translate3d(0,0,0);
        margin-left: -3px;
    }

    .DayPicker_weekHeader small {
        font-size: 12px !important;
        color: var(--dark-gray);
        font-weight: 500;
    }


    .mobileDatepicker .DayPicker_transitionContainer__verticalScrollable {
        height: 100% !important;
    }

    .mobileDatepicker .CalendarMonth_caption__verticalScrollable {
        text-align: left;
        padding-top: 20px;
        padding-left: 15px;

    }

    .mobileDatepicker .CalendarMonth_caption__verticalScrollable > strong {
        text-transform: capitalize;
        font-weight: 500 !important;
        font-size: 15px;

    }

    .mobileDatepicker .DayPickerNavigation__verticalScrollable {
        display: none;
    }

    .mobileDatepicker .CalendarDay {
        height: 47px !important;
        width: 47px !important;
        border: 1px solid transparent;
        border-radius: 50%;
        color: var(--black);
        border: 0;
        font-size: 15px;
        vertical-align: middle;
    }

    /* Blue background behind the blue circle */
    .CalendarDay__selected_end > div::before, .CalendarDay__selected_start > div::before {
        position: absolute;
        left: -1px;
        top: 9px;
        height: 29px;
        width: 23px;
        background: var(--light-blue);
        content: ' ';
        z-index: -2;
    }

    /* Blue circle for the selected start and end dates */
    .CalendarDay__selected_end > div::after, .CalendarDay__selected_start > div::after {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background: var(--dark-blue);
        content: ' ';
        z-index: -1;
    }


    /* Positioning override for the start date */
    .CalendarDay__selected_start > div::before, .CalendarDay__selected_start > div::after {
        right: 0 !important;
        left: auto;
    }

    .payment .CalendarDay__selected_span,
    .payment .CalendarDay__hovered_span {
        background: var(--light-blue);
        border-top: 0 !important;
        border-radius: 0;
    }

    .CalendarDay__selected_span > div::before,
    .CalendarDay__hovered_span > div::before {
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        width: 47px;
        height: 9px;
        content: ' ';
    }

    .CalendarDay__selected_span > div::after,
    .CalendarDay__hovered_span > div::after {
        position: absolute;
        background: white;
        bottom: 0;
        left: 0;
        width: 47px;
        height: 9px;
        content: ' ';
    }
}

/* Fix for displaying modals on big tablet - payments screen - requirement */
@media (min-width: 769px) and (max-width: 1049px) {

    .sidebarOpen .DayPicker_weekHeader {
        transform: translateX(0px);
    }

    .sidebarOpen .mobileDatepicker .DayPicker_weekHeader {
        top: 0px;
        padding: 0 !important;
        text-transform: capitalize;
        letter-spacing: 0.75px;
        /*transform: translateZ(10000px);*/
        -webkit-transform: translate3d(0,0,0);
        margin-left: -3px;
    }

    .sidebarOpen .DayPicker_weekHeader small {
        font-size: 12px !important;
        color: var(--dark-gray);
        font-weight: 500;
    }


    .sidebarOpen .mobileDatepicker .DayPicker_transitionContainer__verticalScrollable {
        height: 100% !important;
    }

    .sidebarOpen .mobileDatepicker .CalendarMonth_caption__verticalScrollable {
        text-align: left;
        padding-top: 20px;
        padding-left: 15px;

    }

    .sidebarOpen .mobileDatepicker .CalendarMonth_caption__verticalScrollable > strong {
        text-transform: capitalize;
        font-weight: 500 !important;
        font-size: 15px;

    }

    .sidebarOpen .mobileDatepicker .DayPickerNavigation__verticalScrollable {
        display: none;
    }

    .sidebarOpen .mobileDatepicker .CalendarDay {
        height: 47px !important;
        width: 47px !important;
        border: 1px solid transparent;
        border-radius: 50%;
        color: var(--black);
        border: 0;
        font-size: 15px;
        vertical-align: middle;
    }

    /* Blue background behind the blue circle */
    .sidebarOpen .payment .CalendarDay__selected_end > div::before,
    .sidebarOpen .payment .CalendarDay__selected_start > div::before {
        position: absolute;
        left: -1px;
        top: 9px;
        height: 29px;
        width: 23px;
        background: var(--light-blue);
        content: ' ';
        z-index: -2;
    }

    /* Blue circle for the selected start and end dates */
    .sidebarOpen .CalendarDay__selected_end > div::after,
    .sidebarOpen .CalendarDay__selected_start > div::after {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background: var(--dark-blue);
        content: ' ';
        z-index: -1;
    }


    /* Positioning override for the start date */
    .sidebarOpen .CalendarDay__selected_start > div::before,
    .sidebarOpen .CalendarDay__selected_start > div::after {
        right: 0 !important;
        left: auto;
    }

    .sidebarOpen .payment .CalendarDay__selected_span,
    .sidebarOpen .payment .CalendarDay__hovered_span {
        background: var(--light-blue);
        border-top: 0 !important;
        border-radius: 0;
    }

    .sidebarOpen .CalendarDay__selected_span > div::before,
    .sidebarOpen .CalendarDay__hovered_span > div::before {
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        width: 47px;
        height: 9px;
        content: ' ';
    }

    .sidebarOpen .CalendarDay__selected_span > div::after,
    .sidebarOpen .CalendarDay__hovered_span > div::after {
        position: absolute;
        background: white;
        bottom: 0;
        left: 0;
        width: 47px;
        height: 9px;
        content: ' ';
    }

    .sidebarOpen .payment .CalendarDay {
        height: unset;
        width: unset;
        border: 0px solid transparent;
        border-radius: 0;
        color: var(--black);
        font-size: 11px;
        vertical-align: middle;
    }
}
