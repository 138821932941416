.skeletonRow {


}

.skeletonRowLoading {
    animation: gradient 1500ms linear infinite;
    background-image: linear-gradient(to right, var(--border-color) 10%, white 50%, var(--border-color) 90%);
    /*background-image: linear-gradient(to right, var(--border-color) 5%, white 25%, var(--border-color) 45%, white 65%, var(--border-color) 85%);*/
    background-size: 200% 200%;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse-blue-bg {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: white;
    color: white;
    box-shadow: 9999px 0 0 -5px white;
    animation: dot-pulse-blue-bg 1.7s infinite linear;
    animation-delay: .25s;
}

.dot-pulse-blue-bg::before, .dot-pulse-blue-bg::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: white;
    color: white;
}

.dot-pulse-blue-bg::before {
    box-shadow: 9984px 0 0 -5px white;
    animation: dot-pulse-blue-bg-before 1.7s infinite linear;
    animation-delay: 0s;
    left: -2px;
}

.dot-pulse-blue-bg::after {
    box-shadow: 10014px 0 0 -5px white;
    animation: dot-pulse-blue-bg-after 1.7s infinite linear;
    animation-delay: .5s;
}

@keyframes dot-pulse-blue-bg-before {
    0% {
        box-shadow: 9984px 0 0 -5px white;
    }
    30% {
        box-shadow: 9984px 0 0 2px white;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px white;
    }
}

@keyframes dot-pulse-blue-bg {
    0% {
        box-shadow: 9999px 0 0 -5px white;
    }
    30% {
        box-shadow: 9999px 0 0 2px white;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px white;
    }
}

@keyframes dot-pulse-blue-bg-after {
    0% {
        box-shadow: 10014px 0 0 -5px white;
    }
    30% {
        box-shadow: 10014px 0 0 2px white;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px white;
    }
}

/**
 * ==============================================
 * Dot Pulse White Background
 * ==============================================
 */
.dot-pulse-white-bg {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--dark-blue);
    color: var(--dark-blue);
    box-shadow: 9999px 0 0 -5px var(--dark-blue);
    animation: dot-pulse-white-bg 1.7s infinite linear;
    animation-delay: .25s;
}

.dot-pulse-white-bg::before, .dot-pulse-white-bg::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--dark-blue);
    color: var(--dark-blue);
}

.dot-pulse-white-bg::before {
    box-shadow: 9984px 0 0 -5px var(--dark-blue);
    animation: dot-pulse-white-bg-before 1.7s infinite linear;
    animation-delay: 0s;
    left: -2px;
}

.dot-pulse-white-bg::after {
    box-shadow: 10014px 0 0 -5px var(--dark-blue);
    animation: dot-pulse-white-bg-after 1.7s infinite linear;
    animation-delay: .5s;
}

@keyframes dot-pulse-white-bg-before {
    0% {
        box-shadow: 9984px 0 0 -5px var(--dark-blue);
    }
    30% {
        box-shadow: 9984px 0 0 2px var(--dark-blue);
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px var(--dark-blue);
    }
}

@keyframes dot-pulse-white-bg {
    0% {
        box-shadow: 9999px 0 0 -5px var(--dark-blue);
    }
    30% {
        box-shadow: 9999px 0 0 2px var(--dark-blue);
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px var(--dark-blue);
    }
}

@keyframes dot-pulse-white-bg-after {
    0% {
        box-shadow: 10014px 0 0 -5px var(--dark-blue);
    }
    30% {
        box-shadow: 10014px 0 0 2px var(--dark-blue);
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px var(--dark-blue);
    }
}


/**
 * ==============================================
 * Dot Pulse Yellow Background
 * ==============================================
 */
.dot-pulse-yellow-bg {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--black);
    color: var(--black);
    box-shadow: 9999px 0 0 -5px var(--black);
    animation: dot-pulse-yellow-bg 1.7s infinite linear;
    animation-delay: .25s;
}

.dot-pulse-yellow-bg::before, .dot-pulse-yellow-bg::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--black);
    color: var(--black);
}

.dot-pulse-yellow-bg::before {
    box-shadow: 9984px 0 0 -5px var(--black);
    animation: dot-pulse-yellow-bg-before 1.7s infinite linear;
    animation-delay: 0s;
    left: -2px;
}

.dot-pulse-yellow-bg::after {
    box-shadow: 10014px 0 0 -5px var(--black);
    animation: dot-pulse-yellow-bg-after 1.7s infinite linear;
    animation-delay: .5s;
}

@keyframes dot-pulse-yellow-bg-before {
    0% {
        box-shadow: 9984px 0 0 -5px var(--black);
    }
    30% {
        box-shadow: 9984px 0 0 2px var(--black);
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px var(--black);
    }
}

@keyframes dot-pulse-yellow-bg {
    0% {
        box-shadow: 9999px 0 0 -5px var(--black);
    }
    30% {
        box-shadow: 9999px 0 0 2px var(--black);
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px var(--black);
    }
}

@keyframes dot-pulse-yellow-bg-after {
    0% {
        box-shadow: 10014px 0 0 -5px var(--black);
    }
    30% {
        box-shadow: 10014px 0 0 2px var(--black);
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px var(--black);
    }
}










/**
 * ==============================================
 * Screen Loader Background
 * ==============================================
 */
.screen-pulse {
    position: relative;
    left: -9999px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--skeleton-gray);
    color: var(--skeleton-gray);
    box-shadow: 9999px 0 0 -5px var(--skeleton-gray);
    animation: screen-pulse 1.7s infinite linear;
    animation-delay: .25s;
}

.screen-pulse::before, .screen-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--skeleton-gray);
    color: var(--skeleton-gray);
}

.screen-pulse::before {
    box-shadow: 9984px 0 0 -5px var(--skeleton-gray);
    animation: screen-pulse-before 1.7s infinite linear;
    animation-delay: 0s;
    left: -20px;
}

.screen-pulse::after {
    box-shadow: 10014px 0 0 -5px var(--skeleton-gray);
    animation: screen-pulse-after 1.7s infinite linear;
    animation-delay: .5s;
    left: 20px;
}

@keyframes screen-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px var(--skeleton-gray);
    }
    30% {
        box-shadow: 9984px 0 0 2px var(--skeleton-gray);
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px var(--skeleton-gray);
    }
}

@keyframes screen-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px var(--skeleton-gray);
    }
    30% {
        box-shadow: 9999px 0 0 2px var(--skeleton-gray);
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px var(--skeleton-gray);
    }
}

@keyframes screen-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px var(--skeleton-gray);
    }
    30% {
        box-shadow: 10014px 0 0 2px var(--skeleton-gray);
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px var(--skeleton-gray);
    }
}
